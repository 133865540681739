<div  class="text-xl text-tit font-medium">최신 베팅&레이스</div>
                                        
<ul class="nav nav-boxed-tabs !w-auto mt-2" role="tablist">
    <li id="batting-1-tab" class="nav-item bg-sponsor rounded-l overflow-hidden" role="presentation"> 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='latest_bets'}"  type="button" (click)="onTab('latest_bets')"> 최근베팅 </button> 
    </li>
    <li id="batting-2-tab" class="nav-item bg-sponsor " role="presentation"> 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='high_rollers'}" type="button" (click)="onTab('high_rollers')"> 하이롤러 </button> 
    </li>
    <li id="batting-3-tab" class="nav-item bg-sponsor rounded-r overflow-hidden" role="presentation" > 
        <button class="w-24 sm:w-40 h-14" [ngClass]="{'active':tab =='wager_contest'}" type="button" (click)="tab='wager_contest'"> 롤링 대회 </button> 
    </li>
</ul>
<div class="tab-content mt-5">
    <div class="tab-pane leading-relaxed p-5 bg-sponsor" [ngClass]="{'active': tab == 'latest_bets'}" role="tabpanel" >
        <div class="overflow-x-auto scrollbar_x ">
            <div class="grid grid-cols-5 gap-2 min-w-[580px] rounded">
                <div class="text-center">게임</div>
                <div class="text-center">플레이어</div>
                <div class="text-center">베팅금액</div>
                <div class="text-center">배당</div>
                <div class="text-right">혜택 금액</div>
            </div>
            <swiper-container swiper init="false" [swiperOptions]="latestBetOptions" *ngIf="latestBetItems.length > 0" loading="lazy" style="height:300px;" class="min-w-[580px]">
                <swiper-slide *ngFor="let items of latestBetItems; let i = index;" class="min-w-[580px]">
                    <div class="grid grid-cols-5 gap-2 min-w-[580px] justify-between rounded" >
                        <div class="text-left"><img class="inline-flex mr-1 w-6" src="/assets/custom_img/game/BlackJack.webp" /> {{ items['game_name']}}</div>
                        <div class="text-center"><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: items.uidx })">{{ items.nick }}</b></div>
                        <div class="text-center"><b [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0 && items['status'] != 'IN_PROGRESS', 'text-tit': items['status'] == 'IN_PROGRESS'}">{{ items.bet_amount | number:'1.0-2' }}</b><img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/' + items['wallet_code'] + '.webp'" /></div>
                        <div class="text-center" [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0  && items['status'] != 'IN_PROGRESS'}">{{ items['hit_rate'] | number:'1.2' }}x</div>
                        <div class="text-right" [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0  && items['status'] != 'IN_PROGRESS'}">{{ items['hit_amount'] | number:'1.0-2' }}<img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/' + items['wallet_code'] + '.webp'" /></div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
        <button class="btn h-8 flex px-4 mt-4 mx-auto items-center justify-center border-none bg-back gap-2">
            <span>더 보기</span>
            <i><svg class="w-3.5 h-3.5 fill-basic rotate-90"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
        </button>
    </div>
    <div class="tab-pane leading-relaxed p-5 bg-sponsor" [ngClass]="{'active': tab == 'high_rollers'}" role="tabpanel">
        <div class="overflow-x-auto scrollbar_x ">
            <div class="grid grid-cols-5 gap-2 min-w-[580px] rounded">
                <div class="text-center">게임</div>
                <div class="text-center">플레이어</div>
                <div class="text-center">베팅금액</div>
                <div class="text-center">배당</div>
                <div class="text-right">혜택 금액</div>
            </div>
            <swiper-container swiper init="false" [swiperOptions]="highRollersOptions" *ngIf="highRollersItems.length > 0" loading="lazy" style="height:300px;" class="min-w-[580px]">
                <swiper-slide *ngFor="let items of highRollersItems; let i = index;" class="min-w-[580px]">
                    <div class="grid grid-cols-5 gap-2 min-w-[580px] justify-between rounded" >
                        <div class="text-left"><img class="inline-flex mr-1 w-6" src="/assets/custom_img/game/BlackJack.webp" /> {{ items['game_name']}}</div>
                        <div class="text-center"><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: items.uidx })">{{ items.nick }}</b></div>
                        <div class="text-center"><b [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0 && items['status'] != 'IN_PROGRESS', 'text-tit': items['status'] == 'IN_PROGRESS'}">{{ items.bet_amount | number:'1.0-2' }}</b><img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/' + items['wallet_code'] + '.webp'" /></div>
                        <div class="text-center" [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0  && items['status'] != 'IN_PROGRESS'}">{{ items['hit_rate'] | number:'1.2' }}x</div>
                        <div class="text-right" [ngClass]="{'text-danger': items['hit_amount'] > 0, 'text-primary': items['hit_amount'] == 0  && items['status'] != 'IN_PROGRESS'}">{{ items['hit_amount'] | number:'1.0-2' }}<img class="inline-flex w-5 ml-1" [src]="'/assets/custom_img/coin/' + items['wallet_code'] + '.webp'" /></div>
                    </div>
                </swiper-slide>
            </swiper-container>
        </div>
        <button class="btn h-8 flex px-4 mt-4 mx-auto items-center justify-center border-none bg-back gap-2">
            <span>더 보기</span>
            <i><svg class="w-3.5 h-3.5 fill-basic rotate-90"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Arrow"></use></svg></i>
        </button>
    </div>
    <div class="tab-pane leading-relaxed" [ngClass]="{'active': tab == 'wager_contest'}"  role="tabpanel" aria-labelledby="batting-3-tab">
        <div class="p-5 bg-sponsor rounded">
            <div class="flex flex-wrap items-center gap-5">
                <div class="flex items-center bg-back p-3 h-[150px] rounded" style="flex:4">
                    <div class="w-[45%] h-full flex items-center">
                        <img class="w-auto h-[80%] mx-auto max-w-none" src="/assets/custom_img/trophy.webp" />
                    </div>
                    <div>
                        <div class="flex items-center gap-2 text-primary text-base font-bold">
                            <img class="w-3" src="/assets/custom_img/grass_left.svg" />
                            데일리
                            <img class="w-3" src="/assets/custom_img/grass_right.svg" />
                        </div>
                        <p class="my-1">대회 상금 규모</p>
                        <div class="bg-backlight text-yellow p-2 px-3 text-2xl font-bold rounded">₩<span odometer [value]="jackpot_money"></span></div>
                    </div>
                </div>
                <div class="flex flex-col items-center justify-center bg-back p-3 text-center h-[150px] rounded" style="flex:3">
                    <p>남은 시간</p>
                    <div class="flex items-center justify-center gap-2 mt-2" >
                        <div class="flex flex-col items-center justify-center text-xs w-16 h-16 bg-modaldark rounded border border-solid border-slate-300">
                            <b class="block text-xl text-tit font-extrabold">{{ hours }}</b>
                            시간
                        </div>
                        <div class="flex flex-col items-center justify-center text-xs w-16 h-16 bg-modaldark rounded border border-solid border-slate-300">
                            <b class="block text-xl text-tit font-extrabold">{{ minutes }}</b>
                            분
                        </div>
                        <div class="flex flex-col items-center justify-center text-xs w-16 h-16 bg-modaldark rounded border border-solid border-slate-300">
                            <b class="block text-xl text-tit font-extrabold">{{ seconds }}</b>
                            초
                        </div>
                    </div>
                </div>
                <div class="relative flex items-center justify-center gap-3 bg-back p-3 h-[150px] rounded" style="flex:3">
                    <button class="absolute right-3 top-3" (click)="modal.open($event, 'rolling-info')"><svg class="w-5 h-5 fill-primary"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Help"></use></svg></button>

                    <div class="absolute left-[-3px] top-[-3px] w-[60px] h-[60px]">
                        <img class="w-full h-full" src="/assets/custom_img/winner.webp"/>
                        <p class="absolute left-[-7px] top-[14px] w-full text-center -rotate-45 text-tit text-xs">우승자</p>
                    </div>

                    <div class="relative w-14 h-14 mt-6 rounded-full border-2 border-solid border-yellow-300 cursor-pointer" (click)="modal.open($event, 'profile', { uidx: 1 })">
                        <img class="w-full h-full rounded-full" src="/assets/custom_img/profile_img2.png"/>
                        <p class="absolute bottom-full w-full -mb-1">
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" class="w-5 mx-auto "><path fill="#ffd308" d="M27.924 14.807l-4.892 11.74h-14.063l-4.892-11.74c-1.198-0.105-2.14-1.099-2.14-2.324 0-1.294 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 0.498-0.157 0.957-0.421 1.336 0.827 1.307 2.274 2.18 3.937 2.18 2.182 0 3.999-1.497 4.522-3.516l0.035-0.197 0.131-2.472c-0.698-0.406-1.172-1.153-1.172-2.019 0-1.295 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 0.866-0.474 1.613-1.172 2.019l0.082 2.434 0.084 0.235c0.523 2.018 2.341 3.516 4.522 3.516 1.663 0 3.109-0.873 3.937-2.18-0.264-0.379-0.421-0.839-0.421-1.336 0-1.294 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 1.225-0.942 2.218-2.14 2.324z"></path></svg>
                        </p>
                    </div>
                    <div>
                        <div class="flex items-center gap-3">
                            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" class="w-5 "><path fill="#ffd308" d="M27.924 14.807l-4.892 11.74h-14.063l-4.892-11.74c-1.198-0.105-2.14-1.099-2.14-2.324 0-1.294 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 0.498-0.157 0.957-0.421 1.336 0.827 1.307 2.274 2.18 3.937 2.18 2.182 0 3.999-1.497 4.522-3.516l0.035-0.197 0.131-2.472c-0.698-0.406-1.172-1.153-1.172-2.019 0-1.295 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 0.866-0.474 1.613-1.172 2.019l0.082 2.434 0.084 0.235c0.523 2.018 2.341 3.516 4.522 3.516 1.663 0 3.109-0.873 3.937-2.18-0.264-0.379-0.421-0.839-0.421-1.336 0-1.294 1.049-2.344 2.344-2.344s2.344 1.049 2.344 2.344c0 1.225-0.942 2.218-2.14 2.324z"></path></svg>
                            지난 챔피언
                        </div>
                        <p class="py-1 my-1 font-extrabold text-tit cursor-pointer" (click)="modal.open($event, 'profile', { uidx: 1 })">Naqeeb ullah1</p>
                        <div class="flex items-center gap-1">
                            혜택
                            <img class="w-5" src="/assets/custom_img/coin/BCD.webp" />
                            <p class="text-primary">7,836.38<span class="text-tit">(50%)</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-wrap items-center justify-between gap-3 bg-back mt-5 p-5 rounded" *ngIf="currentUser">
                <div class="flex items-center pr-3" style="flex:1;">
                    <img class="w-7 h-7 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/>
                    <span class="text-tit font-bold">{{ currentUser.uid }}</span>
                </div>
                <div class="pl-5 border-l border-solid border-slate-300" style="flex:1;">
                    <span class="text-xs block">나의 역할</span>
                    <b class="font-bold text-yellow">50th+</b>
                </div>
                <div class="pl-5 border-l border-solid border-slate-300"style="flex:1;">
                    <span class="text-xs block">롤링된</span>
                    <b class="font-bold text-yellow">₩0.00</b>
                </div>
                <div class="text-xs pl-5 border-l border-solid border-slate-300" style="flex:1.5;">
                    <span class="text-tit font-bold">₩774,865,577.34</span>
                    까지
                    <span class="text-yellow p-1 bg-yellow-700 bg-opacity-50 rounded">맨 위로 10</span>
                    롤링이 남았습니다.
                </div>
            </div>
        </div>
        <div class="p-5 bg-sponsor rounded mt-3">
            <div class="flex items-center justify-between">
                <div class="flex items-center gap-2">
                    <p class="relative -ml-5">
                        <svg class="w-20 fill-primary" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 32"><path d="M1.28 0h78.201c0.707 0 1.28 0.573 1.28 1.28 0 0.26-0.079 0.513-0.227 0.727l-10.079 14.603c-0.325 0.47-0.298 1.099 0.065 1.541l9.676 11.756c0.449 0.546 0.371 1.352-0.175 1.802-0.229 0.189-0.517 0.292-0.813 0.292h-77.929c-0.707 0-1.28-0.573-1.28-1.28v-29.44c0-0.707 0.573-1.28 1.28-1.28z"></path></svg>
                        <span class="absolute left-1 top-[8px] flex items-center gap-1 w-full pl-1 text-white text-xs">
                            <svg class="w-4 h-4 animate-spin animate-spin-slow fill-white"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Loading"></use></svg>활성화
                        </span>
                    </p>
                    <span class="text-sub">2023. 12. 3. ~ 2023. 12. 4</span>
                </div>
                <button class="btn-normal p-1 px-3 text-white rounded" (click)="modal.open($event, 'racing-rank')">내역</button>
            </div>
            <div class="overflow-x-auto scrollbar_x">
                <table class="table table-hover noline mo_pad-s rounded min-w-[580px]">
                    <thead class="text-center text-xs text-sub">
                        <tr>
                            <th class="whitespace-nowrap text-left">#</th>
                            <th class="whitespace-nowrap">플레이어</th>
                            <th class="whitespace-nowrap">롤링된</th>
                            <th class="whitespace-nowrap text-right">상품/상금</th>
                        </tr>
                    </thead>
                    <tbody class="font-extrabold text-center cursor-pointer">
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_gold.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_silver.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal"><img class="w-5" src="/assets/custom_img/chat_copper.svg" /></td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">4th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">5th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">6th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">7th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">8th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">M■■</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">9th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img2.png"/><b class="text-tit font-extrabold" (click)="modal.open($event, 'profile', { uidx: 1 })">NotFrank</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                        <tr>
                            <td class="text-left font-normal">10th</td>
                            <td><img class="inline-flex w-6 h-6 mr-3 rounded-full" src="/assets/custom_img/profile_img3.png"/><b class="font-extrabold"><svg class="inline-flex w-3.5 h-3.5 mb-0.5 fill-basic"><use xlink:href="/assets/custom_img/symbol-defs.svg#icon_Hidden"></use></svg> 숨기기</b></td>
                            <td><b class="text-primary">₩8,138,540,579.90</b></td>
                            <td class="text-right text-primary">₩6,071,620.61 <span class="text-sub">(50%)</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>